import { Component, OnInit, Input } from "@angular/core";
import { FailoverChannel } from "../../../pages/channels/channel";
import { Constants } from "../../../constants/constants";
import { RecoveryState } from "src/app/models/shared";
import { ChannelsService } from "src/app/pages/channels/channels.service";

// TODO:: figure out why model isn't set from multiple-confirm-dialog.component
@Component({
    selector: "zx-failover-channel",
    templateUrl: "./zx-failover-channel.component.html"
})
export class ZxFailoverChannelComponent implements OnInit {
    @Input() model: FailoverChannel;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() searchTerm?: string | string[];
    urls = Constants.urls;

    RecoveryState = RecoveryState;
    constructor(private cs: ChannelsService) {}

    ngOnInit(): void {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
