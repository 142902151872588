import * as _ from "lodash";
/**
 * Utility type to convert camelCase string to snake_case string.
 */
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
    ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
    : S;

/**
 * Utility type to convert all keys of an object to snake_case.
 */
type KeysToSnakeCase<T> = {
    [K in keyof T as CamelToSnakeCase<string & K>]: T[K] extends object ? KeysToSnakeCase<T[K]> : T[K];
};

const camelToSnakeCase = (str: string) => {
    if (typeof str !== "string") {
        return str;
    }
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const prepareToServer = <T extends Record<string, unknown>>(requestBody: T): KeysToSnakeCase<T> => {
    return _.mapKeys(requestBody, (value, key) => camelToSnakeCase(key)) as KeysToSnakeCase<T>;
};
