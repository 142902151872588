import React from "react";
import { NodeResizer, XYPosition } from "@xyflow/react";

export type GroupNodeData = {
    id: string;
    position: XYPosition;
    data: any;
    label: string;
    selected: boolean;
};

export default function GroupNode({ data, selected = false }) {
    return (
        <>
            <NodeResizer isVisible={selected} minWidth={100} minHeight={100} />
            <div className="label">{data.label}</div>
        </>
    );
}
