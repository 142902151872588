import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of, ReplaySubject, Subscriber, firstValueFrom } from "rxjs";
import { share, map, catchError, takeWhile, take } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import {
    UdpRtpTarget,
    RtmpPushTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    PublishingTarget,
    AnyTarget,
    RistTarget,
    SrtTarget,
    TargetApiType,
    NdiTarget,
    MediaconnectCDITarget,
    MediaconnectJPEGXSTarget,
    TargetObjectType,
    MediaLiveHttpTarget,
    MediaconnectEntitlementTarget,
    TargetPidMappingTypes
} from "./../channels/channel";
import { Tag, TargetsTraceRoutes, APIResponse, RecoveryState } from "./../../models/shared";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import moment from "moment";
import { UrlBuilderService } from "src/app/services/url-builder.service";
import { SharedService } from "src/app/services/shared.service";
import { SourcePreferenceSelectionValues } from "src/app/components/shared/zx-delivery-channel-source-select/zx-delivery-channel-source-select.component";
import { UsersService } from "../account-management/users/users.service";

export type DeliveryTarget = UdpRtpTarget | RtmpPushTarget | ZixiPullTarget | ZixiPushTarget | RistTarget | SrtTarget;

@Injectable({
    providedIn: "root"
})
export class TargetsService {
    targets: Observable<AnyTarget[]>;
    traceroutes: Observable<TargetsTraceRoutes>;
    private targets$: ReplaySubject<AnyTarget[]>;
    private traceroutes$: ReplaySubject<TargetsTraceRoutes>;
    private dataStore: {
        targets: AnyTarget[];
        traceroutes: TargetsTraceRoutes;
    };

    private applicationHost: string;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private urlBuilderService: UrlBuilderService,
        private sharedService: SharedService,
        private userService: UsersService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });

        this.userService.user.pipe(take(1)).subscribe(u => (this.applicationHost = u.application_host));
    }

    private reset() {
        this.dataStore = {
            targets: [],
            traceroutes: {}
        };

        this.targets$ = new ReplaySubject<AnyTarget[]>(1);
        this.traceroutes$ = new ReplaySubject<TargetsTraceRoutes>(1);
        this.targets = this.targets$.asObservable();
        this.traceroutes = this.traceroutes$.asObservable();
    }

    async getAllTargets() {
        const httpTargets = this.getHttpTargets();
        const rtmpTargets = this.getRtmpTargets();
        const pullTargets = this.getPullTargets();
        const pushTargets = this.getPushTargets();
        const udpRtpTargets = this.getUdpRtpTargets();
        const ristTargets = this.getRistTargets();
        const srtTargets = this.getSrtTargets();
        const ndiTargets = this.getNdiTargets();
        const cdiTargets = this.getCDITargets();
        const jpegxsTargets = this.getJPEGXSTargets();
        const mediaLiveHttpTargets = this.getMediaLiveHTTPTargets();
        const mediaconnectEntitltmentTargets = this.getMediaconnectEntitlementTargets();

        return await Promise.all([
            httpTargets,
            rtmpTargets,
            pullTargets,
            pushTargets,
            udpRtpTargets,
            ristTargets,
            srtTargets,
            ndiTargets,
            cdiTargets,
            jpegxsTargets,
            mediaLiveHttpTargets,
            mediaconnectEntitltmentTargets
        ]);
    }

    async getAdaptiveTargets() {
        const httpTargets = this.getHttpTargets();
        return await Promise.all([httpTargets]);
    }

    async getDeliveryTargets() {
        const rtmpTargets = this.getRtmpTargets();
        const pullTargets = this.getPullTargets();
        const pushTargets = this.getPushTargets();
        const udpRtpTargets = this.getUdpRtpTargets();
        const ristTargets = this.getRistTargets();
        const srtTargets = this.getSrtTargets();
        const ndiTargets = this.getNdiTargets();
        return await Promise.all([
            rtmpTargets,
            pullTargets,
            pushTargets,
            udpRtpTargets,
            ristTargets,
            srtTargets,
            ndiTargets
        ]);
    }

    async getHttpTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Http, ids).toPromise();
    }

    async getRtmpTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Rtmp, ids).toPromise();
    }

    async getPullTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Pull, ids).toPromise();
    }

    async getPushTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Push, ids).toPromise();
    }

    async getUdpRtpTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.UdpRtp, ids).toPromise();
    }

    async getRistTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Rist, ids).toPromise();
    }

    async getSrtTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Srt, ids).toPromise();
    }

    async getNdiTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Ndi, ids).toPromise();
    }

    async getCDITargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.CDI, ids).toPromise();
    }

    async getJPEGXSTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.JPEGXS, ids).toPromise();
    }
    async getMediaLiveHTTPTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.MediaLiveHttp, ids).toPromise();
    }

    async getMediaconnectEntitlementTargets(ids?: number[]) {
        return await this.refreshTargets(TargetApiType.Entitlement, ids).toPromise();
    }

    private prepParams(ids?: number[]) {
        let filter = new HttpParams();
        if (Array.isArray(ids)) {
            ids.forEach(id => {
                filter = filter.append("id", id.toString());
            });
            filter = filter.append("update", "1");
        } else {
            filter = filter.append("fast", "1");
        }
        return filter;
    }

    getTargetApiType(target?, type?: string) {
        let t = "http";
        if (target) t = target.type;
        if (type) t = type;
        //
        let apiType = "http";
        // Type
        if (
            t === "publishing_target" ||
            t === "http" ||
            t === "s3" ||
            t === "mediastore" ||
            t === "gcp" ||
            t === "azure" ||
            t === "youtube"
        ) {
            apiType = "http";
        } else if (t === "rtmp" || t === "rtmp_push") {
            apiType = "rtmp";
        } else if (t === "push" || t === "zixi_push" || t === "mediaconnect") {
            apiType = "push";
        } else if (t === "pull" || t === "zixi_pull") {
            apiType = "pull";
        } else if (t === "udp_rtp") {
            apiType = "udp_rtp";
        } else if (t === "rist") {
            apiType = "rist";
        } else if (t === "srt" || t === "srt_targets") {
            apiType = "srt";
        } else if (t === "ndi" || t === "ndi_targets") {
            apiType = "ndi";
        } else if (t === "cdi" || t === "mediaconnect_cdi_targets") {
            apiType = "cdi";
        } else if (t === "jpegxs" || t === "mediaconnect_jpegxs_targets") {
            apiType = "jpegxs";
        } else if (t === "medialive_http" || t === "medialive_http_targets") {
            apiType = "medialive_http";
        } else if (t === "entitlement") {
            apiType = "entitlement";
        }
        return apiType;
    }

    // DO NOT USE!!
    hackAPIType(target: any): TargetApiType {
        if (target.type === "mediaconnect") return TargetApiType.Push;
        return target.type as TargetApiType;
    }

    prepTarget(target: TargetObjectType, override?: boolean): AnyTarget {
        const anyTarget: AnyTarget = {
            _frontData: {
                saving: null,
                processing: null,
                hasError: null,
                active_broadcaster: null,
                sortableTarget: "",
                lastRefresh: moment().format()
            },
            id: [target.type, target.id].join("-"),
            objId: target.id,
            type: target.type,
            adaptive: ["http", "s3", "mediastore", "gcp", "azure", "youtube"].includes(target.type),
            delivery: ["rtmp", "mediaconnect", "push", "pull", "udp_rtp", "rist", "srt", "ndi"].includes(target.type),
            mediaconnect: ["push", "pull", "udp_rtp", "rist", "cdi", "jpegxs", "entitlement"].includes(target.type),
            medialive: ["medialive_http", "rtmp", "udp_rtp"].includes(target.type),
            push: target.type === "mediaconnect" || target.type === "push",
            target,
            previewable: ["http", "s3", "mediastore", "gcp", "azure", "youtube"].includes(target.type),
            output_target: "",
            channel_name: "",
            channel_type: "",
            type_name: "",
            order: "",
            status: this.getTargetStatus(target),
            apiType: target.type as TargetApiType,
            dynamic: false
        };

        if (target instanceof PublishingTarget && target.gcp_account_id) target.type = "gcp";
        if (target instanceof PublishingTarget && target.azure_account_id) target.type = "azure";

        anyTarget[target.type] = true;

        // Type
        if (target instanceof PublishingTarget) {
            anyTarget.apiType = TargetApiType.Http;

            anyTarget.output_target = target.ingest_url;
            if (target.type === "gcp") anyTarget.type_name = "GCP";
            else if (target.type === "azure") anyTarget.type_name = "Azure";
            else if (target.type === "mediastore") anyTarget.type_name = "MediaStore";
            else if (target.type === "youtube") anyTarget.type_name = "YouTube";
            else anyTarget.type_name = target.type;
        } else if (target instanceof RtmpPushTarget) {
            anyTarget.output_target = target.target;
            anyTarget.type_name = "RTMP";
        } else if (target instanceof ZixiPushTarget && target.type === "mediaconnect") {
            anyTarget.apiType = TargetApiType.Push;

            anyTarget.output_target = ["zixi://", target.target, "/", target.stream_id].join("");
            anyTarget.type_name = "AWS_MEDIA";
        } else if (target instanceof ZixiPushTarget) {
            anyTarget.output_target = ["zixi://", target.target, "/", target.stream_id].join("");
            anyTarget.type_name = "Push";
        } else if (target instanceof ZixiPullTarget) {
            anyTarget.type_name = "Pull";
            anyTarget.dynamic = target.dynamic_targets;
        } else if (target instanceof UdpRtpTarget) {
            anyTarget.type_name = target.rtp ? "RTP" : "UDP";
            anyTarget.output_target = target.host + ":" + target.port;
        } else if (target instanceof RistTarget) {
            anyTarget.apiType = TargetApiType.Rist;
            anyTarget.type_name = "RIST";
            anyTarget.output_target = target.host + ":" + target.port;
        } else if (target instanceof SrtTarget) {
            anyTarget.apiType = TargetApiType.Srt;
            anyTarget.type_name = "SRT";

            if (target.pull_mode) {
                anyTarget.output_target = `${target.hostPrefix}.${this.applicationHost}:${target.port}`;
            } else {
                anyTarget.output_target = `${target.port}`;
            }
        } else if (target.type === "ndi") {
            anyTarget.apiType = TargetApiType.Ndi;
            anyTarget.type_name = "NDI";
            anyTarget.output_target = "";
        } else if (target instanceof MediaconnectCDITarget) {
            anyTarget.apiType = TargetApiType.CDI;
            anyTarget.type_name = "CDI";
            anyTarget.output_target = target.destination_ip + ":" + target.destination_port;
        } else if (target instanceof MediaconnectJPEGXSTarget) {
            anyTarget.apiType = TargetApiType.JPEGXS;
            anyTarget.type_name = "ST 2110 JPEGXS";
            anyTarget.output_target = target.vpc.map(v => v.destination_ip + "@" + v.name).join(";");
        } else if (target instanceof MediaLiveHttpTarget) {
            anyTarget.apiType = TargetApiType.MediaLiveHttp;
            anyTarget.type_name = "MediaLive HTTP";
            anyTarget.output_target = target.ingest_url;
        } else if (target.type === "entitlement") {
            anyTarget.apiType = TargetApiType.Entitlement;
            anyTarget.type_name = "Entitlement";
            anyTarget.output_target = "";
        }

        // Keep existing alertingProfile unless coming from refreshTarget since it is missing from refreshTargets update=1 for some target types
        const existingTarget = _.find(this.dataStore.targets, t => {
            return t.objId === anyTarget.objId && t.apiType === anyTarget.apiType;
        });

        if (existingTarget && !override) {
            if (existingTarget.target.alertingProfile)
                anyTarget.target.alertingProfile = existingTarget.target.alertingProfile;
            if (existingTarget.target.transcodingProfile)
                anyTarget.target.transcodingProfile = existingTarget.target.transcodingProfile;
        }
        //

        // Channel Name
        if (target.adaptiveChannel && target.adaptiveChannel.name) {
            anyTarget.channel_name = target.adaptiveChannel.name;
        } else if (target.deliveryChannel && target.deliveryChannel.name) {
            anyTarget.channel_name = target.deliveryChannel.name;
        } else if (target.mediaconnectFlow && target.mediaconnectFlow.name) {
            anyTarget.channel_name = target.mediaconnectFlow.name;
        } else if (target.mediaLiveChannel && target.mediaLiveChannel.name) {
            anyTarget.channel_name = target.mediaLiveChannel.name;
        }

        // Channel type
        if (target.mediaconnectFlow) {
            anyTarget.channel_type = "mediaconnect";
        } else if (target.mediaLiveChannel) {
            anyTarget.channel_type = "medialive";
        } else if (target.adaptiveChannel) {
            if (target.adaptiveChannel.is_transcoding) {
                anyTarget.channel_type = "transcoding";
            } else {
                anyTarget.channel_type = "adaptive";
            }
        } else if (target.deliveryChannel) {
            if (target.deliveryChannel.failoverChannel || target.deliveryChannel.failover_channel_id) {
                anyTarget.channel_type = "failover";
            } else {
                anyTarget.channel_type = "pass-through";
            }
        }

        // Corrected State
        const STATE = target.generalStatus;

        // Order
        if (
            target.delivery_channel_id == null &&
            target.adaptive_channel_id == null &&
            target.mediaconnect_flow_id == null &&
            target.medialive_channel_id == null
        )
            anyTarget.order = "6";
        else if (
            (target.deliveryChannel && !target.deliveryChannel.is_enabled) ||
            (target.adaptiveChannel && !target.adaptiveChannel.is_enabled) ||
            (target.mediaLiveChannel && !target.mediaLiveChannel.is_enabled) ||
            (target.mediaconnectFlow && !target.mediaconnectFlow.is_enabled)
        )
            anyTarget.order = "5";
        else if (STATE === "pending") anyTarget.order = "4";
        else if (STATE === "good") anyTarget.order = "3";
        else if (STATE === "warning") anyTarget.order = "2";
        else if (STATE === "bad" || STATE === "error") anyTarget.order = "1";
        else anyTarget.order = "6";

        // active_mute
        if (target.active_mute === true) {
            anyTarget.order += "1";
        } else {
            anyTarget.order += "0";
        }

        // acknowledged
        if (target.acknowledged === 0) {
            anyTarget.order += "0";
        } else {
            anyTarget.order += "1";
        }

        if (target.generalStatus) {
            anyTarget.order += this.getTargetStatus(target);
        }
        this.prepStatusSortFields(target);

        // resource tag sort
        if (target.resourceTags)
            target.resourceTags.sort((a: Tag, b: Tag) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );

        // active broadcaster
        if (target.status?.active_broadcasters && target.status?.active_broadcasters.length >= 1) {
            anyTarget._frontData.active_broadcaster = target.status.active_broadcasters[0];
        }

        if (target.status?.active_broadcaster) {
            anyTarget._frontData.active_broadcaster = target.status.active_broadcaster;
        }
        //
        let t = "";
        if (target instanceof ZixiPullTarget) {
            if (target.receiver_id && target.receiver) t += target.receiver.name;
            if (target.broadcaster_id && target.broadcaster)
                t +=
                    target.broadcaster.name +
                    (target.broadcaster.broadcaster_cluster?.name
                        ? " @ " + target.broadcaster.broadcaster_cluster?.name
                        : "");
            if ((target.receiver_id || target.broadcaster_id) && target.output_id) t += " / " + target.output_name;
            if (!target.receiver_id && !target.broadcaster_id) t += target.receiver_name;
        } else t += anyTarget.output_target;
        anyTarget._frontData.sortableTarget = t;

        //
        return anyTarget;
    }

    prepStatusSortFields(target) {
        let scoreAsc = 0;
        let scoreDesc = 0;
        //let useActiveStates = false;
        const status = this.getTargetStatus(target);

        switch (status) {
            case "ERROR":
                scoreAsc = -300;
                scoreDesc = -100;
                //useActiveStates = true;
                break;
            case "WARNING":
                scoreAsc = -400;
                scoreDesc = -200;
                //useActiveStates = true;
                break;
            case "OK":
                scoreAsc = -500;
                scoreDesc = -300;
                break;
            case "PENDING":
                scoreAsc = -200;
                scoreDesc = -400;
                break;
            case "DISABLED":
                scoreAsc = -100;
                scoreDesc = -500;
                break;
            case "TERMINATED":
                scoreAsc = -90;
                scoreDesc = -510;
                break;
            case "NOT_ASSIGNED":
                scoreAsc = -80;
                scoreDesc = -520;
                break;
            case "CHANNEL_DISABLED":
                scoreAsc = -60;
                scoreDesc = -540;
                break;
            case "FLOW_DISABLED":
                scoreAsc = -50;
                scoreDesc = -550;
                break;
            default:
                //Log an error
                // eslint-disable-next-line no-console
                console.log("Error: Invalid status in TargetsService:prepStatusSortFields", target);
                break;
        }

        // active_mute & acknowledged
        const mute = target.active_mute ? 0 : 1;
        const ack = target.acknowledged ? 0 : 2;
        const total = mute + ack;

        scoreAsc += total;
        scoreDesc += total;

        target._sortData = {
            sortableStatusAsc: scoreAsc,
            sortableStatusDesc: scoreDesc
        };
    }
    private updateStore(target: TargetObjectType, merge: boolean): AnyTarget {
        let preppedTarget: AnyTarget;
        if (merge) preppedTarget = this.prepTarget(target);
        else preppedTarget = this.prepTarget(target, true);

        const currentIndex = this.dataStore.targets.findIndex(
            t => t.id === preppedTarget.id && t.type === preppedTarget.type
        );
        if (currentIndex === -1) {
            this.dataStore.targets.push(preppedTarget);
            return preppedTarget;
        }

        if (merge) {
            const current = this.dataStore.targets[currentIndex];
            if (current.target.childZixiPulls && !preppedTarget.target.childZixiPulls)
                preppedTarget.target.childZixiPulls = current.target.childZixiPulls;
            Object.assign(current, preppedTarget);
            return current;
        }

        this.dataStore.targets[currentIndex] = preppedTarget;
        return preppedTarget;
    }

    getTargetStatus(target, channelParam?) {
        const channel =
            channelParam ||
            (target.adaptive_channel_id !== null && target.adaptiveChannel) ||
            (target.delivery_channel_id !== null && target.deliveryChannel) ||
            (target.medialive_channel_id !== null && target.mediaLiveChannel) ||
            (target.mediaconnect_flow_id !== null && target.mediaconnectFlow);

        if (!channel) return "NOT_ASSIGNED";
        if (!target.is_enabled) return "DISABLED";
        if (!channel.is_enabled) return "CHANNEL_DISABLED";
        if ((target.type === "pull" || target.type === "srt") && target.state === "pending") return "PENDING";

        const lastError = this.sharedService.getLastError(target);
        if (lastError) return lastError.short_message;

        return Constants.statuses[target.generalStatus] || Constants.statuses.bad;
    }

    refreshTargets(apiType: TargetApiType, ids?: number[]): Observable<TargetObjectType[]> {
        const targets$ = this.getTargetsNew(apiType, ids).pipe(takeWhile(targets => !!targets));

        targets$.subscribe(targets => {
            (ids || this.dataStore.targets.filter(t => t.apiType === apiType).map(t => t.objId)).forEach(existingId => {
                const newIndex = targets.findIndex(t => t.id === existingId);
                if (newIndex === -1) {
                    const existingIndex = this.dataStore.targets.findIndex(
                        t => t.objId === existingId && t.apiType === apiType
                    );
                    if (existingIndex !== -1) this.dataStore.targets.splice(existingIndex, 1);
                }
            });

            targets.forEach(refreshedTarget => {
                const typedTarget = this.setType(refreshedTarget);
                if (typedTarget === undefined) return;
                this.updateStore(typedTarget, true);
            });

            this.targets$.next(Object.assign({}, this.dataStore).targets);
        });

        return targets$;
    }

    setType(target: TargetObjectType): TargetObjectType {
        let typedTarget;
        if (target.type === "pull") {
            typedTarget = new ZixiPullTarget();
            Object.assign(typedTarget, target as ZixiPullTarget);
        } else if (target.type === "http") {
            typedTarget = new PublishingTarget();
            Object.assign(typedTarget, target as PublishingTarget);
        } else if (target.type === "push") {
            typedTarget = new ZixiPushTarget();
            Object.assign(typedTarget, target as ZixiPushTarget);
        } else if (target.type === "rtmp") {
            typedTarget = new RtmpPushTarget();
            Object.assign(typedTarget, target as RtmpPushTarget);
        } else if (target.type === "udp_rtp") {
            typedTarget = new UdpRtpTarget();
            Object.assign(typedTarget, target as UdpRtpTarget);
        } else if (target.type === "rist") {
            typedTarget = new RistTarget();
            Object.assign(typedTarget, target as RistTarget);
        } else if (target.type === "srt") {
            typedTarget = new SrtTarget();
            Object.assign(typedTarget, target as SrtTarget);
        } else if (target.type === "ndi") {
            typedTarget = new NdiTarget();
            Object.assign(typedTarget, target as NdiTarget);
        } else if (target.type === "mediaconnect") {
            typedTarget = new ZixiPushTarget();
            Object.assign(typedTarget, target as ZixiPushTarget);
        } else if (target.type === "cdi") {
            typedTarget = new MediaconnectCDITarget();
            Object.assign(typedTarget, target as MediaconnectCDITarget);
        } else if (target.type === "jpegxs") {
            typedTarget = new MediaconnectJPEGXSTarget();
            Object.assign(typedTarget, target as MediaconnectJPEGXSTarget);
        } else if (target.type === "medialive_http") {
            typedTarget = new MediaLiveHttpTarget();
            Object.assign(typedTarget, target as MediaLiveHttpTarget);
        } else if (target.type === "entitlement") {
            typedTarget = new MediaconnectEntitlementTarget();
            Object.assign(typedTarget, target as MediaconnectEntitlementTarget);
        } else {
            typedTarget = new PublishingTarget();
            Object.assign(typedTarget, target as PublishingTarget);
        }
        return typedTarget;
    }

    refreshTarget(apiType: TargetApiType | string, id: number, force?: boolean): Observable<TargetObjectType> {
        if (!force && this.dataStore.targets && this.dataStore.targets.length) {
            const t: AnyTarget = this.dataStore.targets.find(t => t.target.id === id && t.apiType === apiType);
            //
            if (t && t.target.hasFullDetails) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(t._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<TargetObjectType>) => {
                        observe.next(t.target);
                        observe.complete();
                    });
                }
            }
        }

        const target$ = this.http
            .get<{
                success: boolean;
                result: TargetObjectType;
            }>(Constants.apiUrl + Constants.apiUrls.target + "/" + apiType + "/" + id)
            .pipe(share());

        target$.subscribe(
            data => {
                const t = data.result;
                const typedTarget = this.setType(t);
                if (typedTarget === undefined) return;
                typedTarget.hasFullDetails = true;

                this.updateStore(typedTarget, false);

                this.targets$.next(Object.assign({}, this.dataStore).targets);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TARGET"), error)
        );
        return target$.pipe(map(r => r.result));
    }

    getCachedTarget(val: string | number, apiType: TargetApiType | string) {
        if (this.dataStore.targets) {
            if (typeof val === "number")
                return this.dataStore.targets.find(t => t.target.id === val && t.apiType === apiType);
            else return this.dataStore.targets.find(t => t.target.name === val && t.apiType === apiType);
        } else return undefined;
    }

    async addTarget(model: Record<string, unknown>, targetApiType: TargetApiType) {
        let targetUrl = null;

        switch (targetApiType) {
            case TargetApiType.Rist:
                targetUrl = Constants.apiUrls.rist;
                break;
            case TargetApiType.Srt:
                targetUrl = Constants.apiUrls.srt;
                break;
            case TargetApiType.UdpRtp:
                targetUrl = Constants.apiUrls.udp_rtp;
                break;
            case TargetApiType.Pull:
                targetUrl = Constants.apiUrls.zixi_pull;
                break;
            case TargetApiType.Push:
                targetUrl = Constants.apiUrls.zixi_push;
                break;
            case TargetApiType.Rtmp:
                targetUrl = Constants.apiUrls.rtmp_push;
                break;
            case TargetApiType.Ndi:
                targetUrl = Constants.apiUrls.ndi;
                break;
            case TargetApiType.Http:
                targetUrl = Constants.apiUrls.publishingTarget;
                break;
            case TargetApiType.CDI:
                targetUrl = Constants.apiUrls.cdi;
                break;
            case TargetApiType.JPEGXS:
                targetUrl = Constants.apiUrls.jpegxs;
                break;
            case TargetApiType.MediaLiveHttp:
                targetUrl = Constants.apiUrls.medialive_http;
                break;
            case TargetApiType.Entitlement:
                targetUrl = Constants.apiUrls.entitlement;
                break;
            default:
                return false;
        }

        try {
            const result = await this.http
                .post<{
                    success: boolean;
                    result: TargetObjectType;
                }>(Constants.apiUrl + targetUrl, model)
                .toPromise();

            const target = result.result;

            const typedTarget = this.setType(target);
            if (typedTarget === undefined) return;

            this.updateStore(typedTarget, false);

            this.targets$.next(Object.assign({}, this.dataStore).targets);

            return target;
        } catch (error) {
            return false;
        }
    }

    async deleteTarget(target: TargetObjectType) {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl + Constants.apiUrls.target + "/" + target.type + "/" + target.id
                )
                .toPromise();

            const deletedId = result.result;
            const targetIndex = this.dataStore.targets.findIndex(
                t => t.target.id === deletedId && t.target.type === target.type
            );
            if (targetIndex !== -1) this.dataStore.targets.splice(targetIndex, 1);

            this.targets$.next(Object.assign({}, this.dataStore).targets);
            return true;
        } catch (error) {
            return false;
        }
    }

    async updateTarget(target: TargetObjectType, model: Record<string, unknown>) {
        try {
            const result = await firstValueFrom(
                this.http.put<{
                    success: boolean;
                    result: TargetObjectType;
                }>(Constants.apiUrl + Constants.apiUrls.target + "/" + target.type + "/" + target.id, model)
            );

            const updatedTarget = result.result;

            const typedTarget = this.setType(updatedTarget);
            if (typedTarget === undefined) return;

            const prepedTarget = this.updateStore(typedTarget, true);

            this.targets$.next(Object.assign({}, this.dataStore).targets);
            return prepedTarget;
        } catch (error) {
            if (error.status === 428) return true;
            else return false;
        }
    }

    // Traceroutes
    refreshTraceroutes(t: TargetObjectType): Observable<TargetsTraceRoutes> {
        const traceroutes$ = this.http
            .get<TargetsTraceRoutes>(
                Constants.apiUrl +
                    Constants.apiUrls.target +
                    "/" +
                    `${t.type}` +
                    "/" +
                    `${t.id}` +
                    Constants.apiUrls.traceroute +
                    "s"
            )
            .pipe(share());
        traceroutes$.subscribe(
            data => {
                let traceroutes = data;

                if (!t.is_enabled) traceroutes = { success: false, error: this.translate.instant("TARGET_DISABLED") };
                else if (!t.delivery_channel_id && !t.adaptive_channel_id)
                    traceroutes = { success: false, error: this.translate.instant("TARGET_NOT_ASSIGNED") };
                else this.dataStore.traceroutes = traceroutes;

                this.traceroutes$.next(Object.assign({}, this.dataStore).traceroutes);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRACEROUTE"), error)
        );
        return traceroutes$;
    }

    // getResourceTags
    getResourceTags(type: string): Observable<Tag[]> {
        return new Observable((observe: Subscriber<Tag[]>) => {
            this.http
                .get<{ success: boolean; result: Tag[] }>(
                    Constants.apiUrl + Constants.apiUrls.resourceTags + "/" + type
                )
                .subscribe(
                    result => {
                        observe.next(result.result);
                        observe.complete();
                    },
                    // eslint-disable-next-line no-console
                    error => console.log(this.translate.instant("COULD_NOT_LOAD_RESOURCE_TAGS"), error)
                );
        });
    }

    openTargetPreview(target: PublishingTarget) {
        let host = window.location.origin;
        const source = target.playback_url ? target.playback_url : null;
        const sourceLow = source ? source.toLowerCase() : "";

        if (!sourceLow.startsWith("https") && host.startsWith("https")) host = host.replace("https", "http");
        window.open(
            host + "/play?url=" + this.urlBuilderService.encodeRFC3986URIComponent(source),
            "zenplayer",
            "width=640,height=470"
        );
    }

    openTargetDASHPreview(target: PublishingTarget) {
        if (!(target.type === "s3" || target.type === "mediastore")) return;

        let source = target.ingest_url ? target.ingest_url : null;
        if (target.ingest_url.slice(-4) !== ".mpd") {
            if (target.ingest_url.slice(-5) === ".m3u8") source = target.ingest_url.slice(0, -5) + ".mpd";
            else {
                if (source.slice(-1) !== "/") source += "/";
                source += "dash.mpd";
            }
        }

        let host = window.location.origin;
        const sourceLow = source ? source.toLowerCase() : "";

        if (!sourceLow.startsWith("https") && host.startsWith("https")) host = host.replace("https", "http");
        window.open(
            host + "/play?url=" + this.urlBuilderService.encodeRFC3986URIComponent(source),
            "zenplayer",
            "width=640,height=470"
        );
    }

    openTargetHLSPreview(target: PublishingTarget) {
        if (!(target.type === "s3" || target.type === "mediastore" || target.type === "azure")) return;

        let source = target.ingest_url;
        if (target.encapsulation === "hls") {
            if (target.ingest_url.slice(-5) !== ".m3u8") {
                if (source.slice(-1) !== "/") source += "/";
                source += "index.m3u8";
            }
        } else if (target.encapsulation === "dash") {
            if (target.ingest_url.slice(-5) !== ".m3u8") {
                if (target.ingest_url.slice(-4) === ".mpd") source = target.ingest_url.slice(0, -4) + ".m3u8";
                else {
                    if (source.slice(-1) !== "/") source += "/";
                    source += "index_mp4.m3u8";
                }
            }
        }

        let host = window.location.origin;
        const sourceLow = source ? source.toLowerCase() : "";

        if (!sourceLow.startsWith("https") && host.startsWith("https")) host = host.replace("https", "http");
        window.open(
            host + "/play?url=" + this.urlBuilderService.encodeRFC3986URIComponent(source),
            "zenplayer",
            "width=640,height=470"
        );
    }

    /**
     * @param apiType enum
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @returns Observable resolve with an array of targets or in case of error it resolves with null and log the error to the console
     */
    private getTargetsNew(apiType: TargetApiType, ids: number[] = null): Observable<TargetObjectType[]> {
        const req$ = this.http.get<APIResponse<TargetObjectType[]>>(
            Constants.apiUrl + Constants.apiUrls.target + "/" + apiType,
            {
                params: this.prepParams(ids)
            }
        );

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                if (
                    apiType === TargetApiType.Entitlement &&
                    Array.isArray(response.result) &&
                    response.result.length > 0 &&
                    !response.result[0].type
                ) {
                    // special case because entitlement targets don't have "type" stored in DB
                    response.result.map(target => (target.type = "entitlement"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TARGETS"), error);
                return of(null);
            }),
            share()
        );
    }

    getPullTargetsNew(ids: number[] = null): Observable<ZixiPullTarget[]> {
        return this.getTargetsNew(TargetApiType.Pull, ids) as Observable<ZixiPullTarget[]>;
    }

    getMediaLiveHTTPTargetsNew(ids: number[] = null): Observable<MediaLiveHttpTarget[]> {
        return this.getTargetsNew(TargetApiType.MediaLiveHttp, ids) as Observable<MediaLiveHttpTarget[]>;
    }

    getPushTargetsNew(ids: number[] = null): Observable<ZixiPushTarget[]> {
        return this.getTargetsNew(TargetApiType.Push, ids) as Observable<ZixiPushTarget[]>;
    }

    getHttpTargetsNew(ids: number[] = null): Observable<PublishingTarget[]> {
        return this.getTargetsNew(TargetApiType.Http, ids) as Observable<PublishingTarget[]>;
    }

    getRistTargetsNew(ids: number[] = null): Observable<RistTarget[]> {
        return this.getTargetsNew(TargetApiType.Rist, ids) as Observable<RistTarget[]>;
    }

    getUdpRtpTargetsNew(ids: number[] = null): Observable<UdpRtpTarget[]> {
        return this.getTargetsNew(TargetApiType.UdpRtp, ids) as Observable<UdpRtpTarget[]>;
    }

    getRtmpTargetsNew(ids: number[] = null): Observable<RtmpPushTarget[]> {
        return this.getTargetsNew(TargetApiType.Rtmp, ids) as Observable<RtmpPushTarget[]>;
    }

    getSrtTargetsNew(ids: number[] = null): Observable<SrtTarget[]> {
        return this.getTargetsNew(TargetApiType.Srt, ids) as Observable<SrtTarget[]>;
    }

    getNdiTargetsNew(ids: number[] = null): Observable<NdiTarget[]> {
        return this.getTargetsNew(TargetApiType.Ndi, ids) as Observable<NdiTarget[]>;
    }

    getEntitlementTargetsNew(ids: number[] = null): Observable<MediaconnectEntitlementTarget[]> {
        return this.getTargetsNew(TargetApiType.Entitlement, ids) as Observable<MediaconnectEntitlementTarget[]>;
    }

    getCDITargetsNew(ids?: number[]): Observable<MediaconnectCDITarget[]> {
        return this.getTargetsNew(TargetApiType.CDI, ids) as Observable<MediaconnectCDITarget[]>;
    }

    getJPEGXSTargetsNew(ids?: number[]): Observable<MediaconnectJPEGXSTarget[]> {
        return this.getTargetsNew(TargetApiType.JPEGXS, ids) as Observable<MediaconnectJPEGXSTarget[]>;
    }

    isAltPath(target: TargetObjectType) {
        if (!(target as any).primary_channel_id || !("primary_channel_id" in target)) return false;
        if ((target as any).primary_channel_id === target.delivery_channel_id) {
            return false;
        } else if ((target as any).primary_channel_id === target.adaptive_channel_id) {
            return false;
        } else return true;
    }

    getDisasterRecoveryState(target: TargetObjectType) {
        if (!("primary_channel_id" in target) || !target.primary_channel_id) return RecoveryState.none;

        if (target.deliveryChannel) {
            if (target.primary_channel_id === target.delivery_channel_id) {
                if (target.deliveryChannel.failoverChannel?.alt_channel_id) {
                    return RecoveryState.primary;
                }
                if (target.deliveryChannel.alt_channel_id) return RecoveryState.primary;
                return RecoveryState.none;
            } else {
                return RecoveryState.alternative;
            }
        } else if (target.adaptiveChannel) {
            if (target.primary_channel_id === target.adaptive_channel_id) {
                if (target.adaptiveChannel.alt_channel_id) return RecoveryState.primary;
                return RecoveryState.none;
            } else {
                return RecoveryState.alternative;
            }
        }

        return RecoveryState.none;
    }

    toggleDisasterRecoveryState(
        target: TargetPidMappingTypes,
        preferredSourceId: SourcePreferenceSelectionValues | null
    ) {
        const drState = this.getDisasterRecoveryState(target);
        if (drState === RecoveryState.none) return;

        let requestParams = {};

        if (drState === RecoveryState.primary) {
            if (target.adaptiveChannel) {
                requestParams = { adaptive_channel_id: target.adaptiveChannel.alt_channel_id };
            } else if (target.deliveryChannel) {
                if (target.deliveryChannel.failoverChannel) {
                    requestParams = {
                        failover_channel_id: target.deliveryChannel.failoverChannel.alt_channel_id
                    };
                } else {
                    if (typeof preferredSourceId !== "number")
                        throw new Error("Preferred source is required for this operation");
                    requestParams = {
                        delivery_channel_id: target.deliveryChannel.alt_channel_id,
                        preferred_source: preferredSourceId
                    };
                }
            }
        } else if (drState === RecoveryState.alternative) {
            if (target.adaptiveChannel) {
                requestParams = {
                    adaptive_channel_id: target.primary_channel_id,
                    preferred_source: target.primary_source_id
                };
            } else if (target.deliveryChannel) {
                if (target.deliveryChannel.failoverChannel) {
                    requestParams = {
                        delivery_channel_id: target.primary_channel_id
                    };
                } else {
                    requestParams = {
                        delivery_channel_id: target.primary_channel_id,
                        preferred_source: target.primary_source_id
                    };
                }
            }
        }

        return this.updateTarget(target, requestParams);
    }
}
