import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ClustersService } from "../../../../pages/clusters/clusters.service";
import { SomeZixiObject, Tag } from "src/app/models/shared";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import * as _ from "lodash";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { MediaConnectSourcesService } from "src/app/pages/sources/mediaconnect-sources.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";

@Component({
    selector: "app-edit-tags-dialog",
    templateUrl: "./edit-tags-dialog.component.html"
})
export class EditTagsDialogComponent implements OnInit, OnDestroy {
    object: SomeZixiObject;
    type: string;
    canEdit: boolean;

    loading: boolean;
    saving: boolean;

    form = new FormGroup<any>([]);
    tagsControl = new FormControl<Tag[]>([], [Validators.required]);

    private routeSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private cs: ClustersService,
        private ss: SourcesService,
        private mcs: MediaConnectSourcesService,
        private chs: ChannelsService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.activeModal.close();
            });
    }

    ngOnInit() {
        this.tagsControl.setValue(this.object.resourceTags);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const tags = this.tagsControl.value;
        const model = {
            resource_tag_ids: _.map(tags, "id")
        };

        // TODO: handle many object type
        if (this.type === "cluster") {
            const result = await this.cs.updateCluster(this.object as any, model);
            if (result) {
                this.saving = false;
                this.activeModal.close(result);
            }
        }

        if (this.type === "source") {
            const result = await this.ss.updateSource(this.object as any, model);
            if (result) {
                this.saving = false;
                this.activeModal.close(result);
            }
        }

        if (this.type === "mediaconnect_source") {
            const result = await this.mcs.updateMediaConnectSource(this.object as any, model);
            if (result) {
                this.saving = false;
                this.activeModal.close(result);
            }
        }

        if (this.type === "channel") {
            const result = await this.chs.updateChannel(this.object as any, model);
            if (result) {
                this.saving = false;
                this.activeModal.close(result);
            }
        }

        this.saving = false;
    }
}
