import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
    ReactFlow,
    Background,
    ConnectionLineType,
    Controls,
    MarkerType,
    MiniMap,
    Panel,
    ReactFlowInstance,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "@xyflow/react";
import ELK from "elkjs";
import _ from "lodash";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import BroadcasterNode from "./react-components/broadcasterNode";
import SourceNode from "./react-components/sourceNode";
import MediaConnectSourceNode from "./react-components/mediaconnectSourceNode";
import ZecNode from "./react-components/zecNode";
import TargetNode from "./react-components/targetNode";
import ChannelNode from "./react-components/channelNode";
import GroupNode from "./react-components/groupNode";

import { SmartEdge, SmartStepEdge, SmartBezierEdge } from "@tisoap/react-flow-smart-edge";
import CustomEdge from "./react-components/customEdge";
import ElkEdge from "./react-components/elkEdge";

const edgeType = "smart";

/* const edgeTypes = {
    "custom-edge": CustomEdge
}; */

const edgeTypes = {
    smart: ElkEdge
};

let ports = [];

const formatData = (props, direction, showDetails?, showBroadcasters?, showThumbnails?, showClusters?) => {
    ports = [];
    let nodes = [];
    let edges = [];

    // get nodes
    if (props.objects) {
        const entries = Object.entries(props.objects);
        for (const [key, value] of entries) {
            if (!nodes.find(node => node.id === key)) {
                if (!(!showBroadcasters && (value as any).server)) {
                    nodes.push({
                        id: key,
                        data: {
                            ...(value as any).data
                        }
                    });
                }
            }
        }
    }

    // get edges
    if (props.paths) {
        const entries = Object.entries(props.paths);
        for (const [key, value] of entries) {
            for (const [k, v] of Object.entries(value)) {
                if (!edges.find(edge => edge.id === key + "-" + k + "-ID")) {
                    if (nodes.find(node => node.id === key) && nodes.find(node => node.id === k)) {
                        let isAnimated = false;
                        const nodeOne = nodes.find(node => node.id === key);
                        const nodeTwo = nodes.find(node => node.id === k);
                        if (
                            (nodeOne.data.status === "good" || nodeOne.data.status === "warning") &&
                            (nodeTwo.data.status === "good" || nodeTwo.data.status === "warning")
                        )
                            isAnimated = true;

                        const def = {
                            id: key + "-" + k + "-ID",
                            source: key,
                            target: k,
                            type: edgeType,
                            animated: v.isActive ? true : isAnimated ? true : false,
                            deletable: false,
                            selectable: false,
                            sourceHandle: key + "-" + k + "-" + "sourceHandle",
                            targetHandle: key + "-" + k + "-" + "targetHandle",
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 12,
                                height: 12,
                                color: "#757575"
                            },
                            label: ""
                        };

                        if (v.tags?.length) {
                            def.label = v.tags.join(", ");
                        }
                        edges.push(def);
                    }
                }
            }
        }
    }

    // make nested structure as required by elkjs
    let children = [];
    let updatedClusters = {};
    if (props.clusters) {
        const entries = Object.entries(props.clusters);

        for (const [key, value] of entries) {
            if (!children.find(node => node.id === key)) {
                const grandchildren = [];
                for (const [k, v] of Object.entries(value)) {
                    const ggc = [];
                    for (const i in v) {
                        let node = nodes.find(node => node.id === v[i]);
                        if (node) {
                            node.group = k === "No Active Broadcaster" ? "No Active Broadcaster-" + v[0] : k;
                            const updatedNode = nodeSettings(node, edges, direction, showDetails, showThumbnails);
                            node = updatedNode;
                            if (!ggc.find(node => node.id === v[i])) ggc.push(node);
                        }
                    }

                    grandchildren.push({
                        id: k === "No Active Broadcaster" ? "No Active Broadcaster-" + v[0] : k,
                        data: { label: k },
                        group: key,
                        width: 100,
                        height: 100,
                        type: "group",
                        edges: [],
                        children: ggc,
                        layoutOptions: {
                            ...midElkOptions
                        }
                    });
                }

                children.push({
                    id: key,
                    data: { label: "Cluster " + key.split("(")[0] },
                    width: 100,
                    height: 100,
                    type: "group",
                    edges: [],
                    children: grandchildren,
                    layoutOptions: {
                        ...topElkOptions
                    }
                });
            }
        }

        if (!showClusters) {
            const updatedLayers = [];
            for (const topLvl of children) {
                if (topLvl.type === "group") {
                    for (const child of topLvl.children) {
                        updatedLayers.push(child);
                    }
                } else {
                    updatedLayers.push(topLvl);
                }
            }
            children = [...updatedLayers];

            const entries = Object.entries(props.clusters);
            for (const [key, value] of entries) {
                updatedClusters = { ...updatedClusters, ...(value as any) };
            }
        } else {
            updatedClusters = props.clusters;
        }
    }

    // add non nested nodes
    if (nodes && nodes.length) {
        for (const node of nodes) {
            const exists = nestedNodeExists(node.id, children);
            if (!exists) {
                const updatedNode = nodeSettings(node, edges, direction, showDetails, showThumbnails);
                if (!showClusters) children.unshift(updatedNode);
                else children.push(updatedNode);
            }
        }
    }

    // add edges to nested nodes
    const nonNestedEdges = [];
    for (const edge of edges) {
        const result = findEdgeParent(updatedClusters, edge);
        if (!result) {
            nonNestedEdges.push(edge);
        } else {
            const exists = nestedNodeExists(result, children);
            if (exists) {
                exists.edges.push(edge);
            }
        }
    }

    return { children, edges: nonNestedEdges };
};

const findEdgeParent = (clusters, edge) => {
    if (clusters) {
        const common = findClosestCommonParent(clusters, edge.source, edge.target);

        if (!common.commonParentPath || common.commonParentPath === "") {
            return false;
        } else {
            const pathArray = common.commonParentPath.split(".");
            if (pathArray.length === 1) {
                return pathArray[0];
            } else {
                const lastID = pathArray[pathArray.length - 1];
                return lastID;
            }
        }
    }
};

// Function to find the path of a target value in the nested object
function findValuePathInObject(obj, target, currentPath = "") {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            let newKey = "";
            if (key === "No Active Broadcaster") newKey = key + "-" + value[0];
            else newKey = key;

            const newPath = currentPath ? `${currentPath}.${newKey}` : newKey;

            if (Array.isArray(value)) {
                if (value.includes(target)) {
                    return { found: true, path: newPath };
                }
            } else if (typeof value === "object" && value !== null) {
                const result = findValuePathInObject(value, target, newPath);
                if (result.found) {
                    return result;
                }
            }
        }
    }
    return { found: false, path: null };
}

// Function to find the closest common parent path from two paths
function findCommonParentPath(path1, path2) {
    const pathArray1 = path1.split(".");
    const pathArray2 = path2.split(".");
    let commonPath = "";

    for (let i = 0; i < Math.min(pathArray1.length, pathArray2.length); i++) {
        if (pathArray1[i] === pathArray2[i]) {
            commonPath = commonPath ? `${commonPath}.${pathArray1[i]}` : pathArray1[i];
        } else {
            break;
        }
    }

    return commonPath;
}

// Function to find the closest common parent of two target values
function findClosestCommonParent(obj, target1, target2) {
    const result1 = findValuePathInObject(obj, target1);
    const result2 = findValuePathInObject(obj, target2);

    if (result1.found && result2.found) {
        const commonParentPath = findCommonParentPath(result1.path, result2.path);
        return { found: true, commonParentPath };
    } else {
        return { found: false, commonParentPath: null };
    }
}

const nodeSettings = (node, edges, direction: string, showDetails: boolean, showThumbnails: boolean) => {
    node.width = 280;
    node.height = 240;

    const sourcePorts = [];
    const sourceEdges = edges.filter(edge => edge.source === node.id);
    for (const edge of sourceEdges) {
        sourcePorts.push({
            id: edge.sourceHandle,
            properties: { side: direction === "DOWN" ? "SOUTH" : "EAST" }
        });
    }

    const targetPorts = [];
    const targetEdges = edges.filter(edge => edge.target === node.id);
    for (const edge of targetEdges) {
        targetPorts.push({
            id: edge.targetHandle,
            properties: { side: direction === "DOWN" ? "NORTH" : "WEST" }
        });
    }

    node.data.sourceHandles = [...sourcePorts];
    node.data.targetHandles = [...targetPorts];
    node.data.direction = direction;
    node.ports = [...targetPorts, ...sourcePorts];
    node.properties = {
        "elk.portConstraints": "FIXED_SIDE"
    };

    ports = ports.concat([...targetPorts, ...sourcePorts]);
    node.layoutOptions = {
        ...btmElkOptions
    };

    const updatedNode = setNodeTypeAndSize(node, showDetails, showThumbnails);
    node = updatedNode;

    return node;
};

const setNodeTypeAndSize = (node, showDetails?: boolean, showThumbnails?: boolean) => {
    if (!showDetails) node.data.fields = [];
    let height = 60 + node.data?.fields?.length * 25;
    if (node.data.type && node.data.type !== "source") {
        node.type = node.data.type;
        node.height = height;
        node.style = {
            width: 280,
            height: height
        };
    } else if (node.data.type === "source") {
        node.type = "source";
        if (!showThumbnails) {
            node.data.showThumbnail = false;
            height = 63 + node.data.fields.length * 25;
        } else {
            node.data.showThumbnail = true;
            height = 63 + (!node.data.failoverChannel ? 158 : 0) + node.data.fields.length * 25;
        }
        node.height = height;
        node.style = {
            width: 280,
            height: height
        };
    } else node.type = "group";
    return node;
};

const nestedNodeExists = (id: string, nestedNodes) => {
    for (const n1 of nestedNodes) {
        if (n1.id === id) return n1;
        if (n1.children) {
            for (const n2 of n1?.children) {
                if (n2.id === id) return n2;
                if (n2.children) {
                    for (const n3 of n2?.children) {
                        if (n3.id === id) return n3;
                    }
                }
            }
        }
    }
    return false;
};

const elk = new ELK();

const sharedElkOptions = {
    "elk.layered.spacing.baseValue": "32",
    "elk.edgeRouting": "ORTHOGONAL",
    // "elk.layered.nodePlacement.strategy": "NETWORK_SIMPLEX",
    // "elk.layered.nodePlacement.strategy": "SIMPLE",
    "elk.layered.spacing.nodeNodeBetweenLayers": "64",
    "elk.spacing.nodeNode": "64",
    // "elk.layered.spacing.edgeEdgeBetweenLayers": "16",
    // "elk.spacing.edgeEdge": "16",
    // "elk.layered.spacing.edgeNodeBetweenLayers": "16",
    // "elk.spacing.edgeNode": "16",
    // "elk.spacing.labelNode": "16",
    //"elk.edge.thickness": "3",
    // "elk.spacing.portPort": "16",
    "elk.layered.mergeEdges": "false",
    "elk.layered.mergeHierarchyEdges": "false",
    // "elk.layered.crossingMinimization.strategy": "INTERACTIVE",
    // "elk.layered.crossingMinimization.semiInteractive": "true",
    // "elk.separateConnectedComponents": "false",
    // "elk.layered.feedbackEdges": "true",
    // "elk.layered.crossingMinimization.forceNodeModelOrder": "true",
    // "elk.layered.considerModelOrder.longEdgeStrategy": "EQUAL",
    "elk.layered.considerModelOrder.crossingCounterNodeInfluence": "0.001",
    "elk.layered.considerModelOrder.crossingCounterPortInfluence": "0.001",
    "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
    "elk.layered.nodePlacement.bk.edgeStraightening": "IMPROVE_STRAIGHTNESS"
};

const elkOptions = {
    ...sharedElkOptions,
    "elk.algorithm": "layered",
    "elk.hierarchyHandling": "INCLUDE_CHILDREN",
    "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES"
};
const topElkOptions = {
    ...sharedElkOptions,
    "elk.padding": "[top=32.0,left=16.0,bottom=16.0,right=16.0]"
};
const midElkOptions = {
    ...sharedElkOptions,
    "elk.padding": "[top=32.0,left=16.0,bottom=16.0,right=16.0]"
};
const btmElkOptions = {
    ...sharedElkOptions,
    "elk.portConstraints": "FIXED_SIDE"
};

// uses elkjs to give each node a layouted position
export const getLayoutedNodes = async (nodes, edges, direction) => {
    const graph = {
        id: "root",
        layoutOptions: {
            "elk.direction": direction,
            ...elkOptions
        },
        children: nodes,
        edges: edges.map(e => ({
            id: e.id,
            ...e,
            sources: [e.sourceHandle || e.source],
            targets: [e.targetHandle || e.target]
        }))
    };

    const layoutedGraph = await elk.layout(graph);
    const layout2 = layoutedGraph as any;

    let nestedEdges = [];
    if (layout2.children?.length) {
        for (const child of layout2.children) {
            if (child.edges?.length) nestedEdges.push(...child.edges);
            if (child.children?.length) {
                for (const grandchild of child.children) {
                    if (grandchild.edges?.length) nestedEdges.push(...grandchild.edges);
                    if (grandchild.children?.length) {
                        for (const ggc of grandchild.children) {
                            if (ggc.edges?.length) nestedEdges.push(...ggc.edges);
                        }
                    }
                }
            }
        }
    }

    // make flat structure for react flow
    const flatNodes = layout2.children.reduce((result, current) => {
        result.push({
            id: current.id,
            position: { x: current.x, y: current.y },
            absPosition: { x: current.x, y: current.y },
            data: current.data,
            width: current.width,
            height: current.height,
            type: current.type,
            ports: current.ports,
            properties: current.properties,
            layoutOptions: current.layoutOptions
        });

        current.children?.forEach(child => {
            result.push({
                id: child.id,
                position: { x: child.x, y: child.y },
                absPosition: { x: child.x + current.x, y: child.y + current.y },
                data: child.data,
                width: child.width,
                height: child.height,
                type: child.type,
                ports: child.ports,
                properties: child.properties,
                layoutOptions: child.layoutOptions,
                parentId: current.id,
                extent: "parent"
            });

            child.children?.forEach(grandchild => {
                result.push({
                    id: grandchild.id,
                    position: { x: grandchild.x, y: grandchild.y },
                    absPosition: { x: grandchild.x + child.x + current.x, y: grandchild.y + child.y + current.y },
                    data: grandchild.data,
                    width: grandchild.width,
                    height: grandchild.height,
                    type: grandchild.type,
                    ports: grandchild.ports,
                    properties: grandchild.properties,
                    layoutOptions: grandchild.layoutOptions,
                    parentId: child.id,
                    extent: "parent"
                });
            });
        });

        return result;
    }, []);

    const allEdges = [...layout2.edges, ...nestedEdges];

    // pass edge section data
    const flatEdges = allEdges.map(e => ({
        ...e,
        data: { container: e.container, sections: e.sections }
    }));

    return {
        nodes: flatNodes,
        edges: flatEdges
    };
};

const nodeTypes = {
    feeder: ZecNode,
    receiver: ZecNode,
    zec: ZecNode,
    broadcaster: BroadcasterNode,
    source: SourceNode,
    mediaconnect_source: MediaConnectSourceNode,
    target: TargetNode,
    channel: ChannelNode,
    group: GroupNode
};

const ReactDiagramComponent = props => {
    const { getNodes, getEdges, setNodes, setEdges, fitView } = useReactFlow();
    const [nodes, , onNodesChange] = useNodesState([]);
    const [edges, , onEdgesChange] = useEdgesState([]);

    const [object, setObject] = useState(null);
    const [oldProps, setProps] = useState(null);
    const [direction, setDirection] = useState(localStorage.getItem("diagramOrientation") ? localStorage.getItem("diagramOrientation") : "DOWN");
    const [showDetails, setShowDetails] = useState(localStorage.getItem("diagramDetails") === "false" ? false : true);
    const [showBroadcasters, setShowBroadcasters] = useState(localStorage.getItem("diagramBroadcasters") === "false" ? false : true);
    const [showClusters, setShowClusters] = useState(localStorage.getItem("diagramClusters") === "false" ? false : true);
    const [showThumbnails, setShowThumbnails] = useState(localStorage.getItem("diagramThumbnails") === "false" ? false : true);

    const layoutNodes = async (dataNodes, dataEdges, direction) => {
        const { nodes, edges } = await getLayoutedNodes(dataNodes, dataEdges, direction);
        setNodes(nodes);
        setEdges(edges);
        setTimeout(() => {
            fitView();
        }, 0);
    };

    const updateNodeData = async (showDet: boolean, showThumbs: boolean) => {
        setNodes(nds =>
            nds.map(node => {
                const keys = Object.keys(props.objects);
                const index = keys.indexOf(node.id);
                const value = props.objects[keys[index]];
                if (value?.data) {
                    node.data = {
                        ...node.data,
                        ...value.data
                    };
                }
                const resized = setNodeTypeAndSize(node, showDet, showThumbs);
                return resized;
            })
        );
    };

    const updateEdgeData = async () => {
        setEdges(edges =>
            edges.map(edge => {
                const keys = Object.keys(props.objects);
                // get source node
                const index = keys.indexOf(edge.source);
                const value = props.objects[keys[index]];
                // get target node
                const index2 = keys.indexOf(edge.target);
                const value2 = props.objects[keys[index2]];

                let isAnimated = false;
                if ((value.data.status === "good" || value.data.status === "warning") && (value2.data.status === "good" || value2.data.status === "warning"))
                    isAnimated = true;

                // get path
                const pathsKeys = Object.keys(props.paths);
                const i = pathsKeys.indexOf(edge.source);
                const v = props.paths[pathsKeys[i]];
                const pathKeys = Object.keys(v);
                const i2 = pathKeys.indexOf(edge.target);
                const v2 = v[pathKeys[i2]];

                // update animated setting based on new data
                edge.animated = v2.isActive ? true : isAnimated ? true : false;

                return edge;
            })
        );
    };

    // Update
    useEffect(() => {
        const fetchData = async () => {
            const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
            await layoutNodes(dataNodes, dataEdges, direction);
        };

        // If diagram base object changed, redraw diagram
        if (object && (props.baseObject?.id !== object.id || props.baseObject?.type !== object.type)) {
            fetchData();
        } else {
            if (props && oldProps) {
                const propsEqual = _.isEqual(props.paths, oldProps.paths);
                const objectsEqual = _.isEqual(Object.keys(props.objects), Object.keys(oldProps.objects));
                // check if new/missing nodes or edges, if so run fetchData
                if (propsEqual && objectsEqual) {
                    // updates nodes without layout redraw
                    updateNodeData(showDetails, showThumbnails);
                    // updates edges without layout redraw
                    updateEdgeData();
                } else {
                    // redraws diagram to update layout
                    fetchData();
                }
            }
        }

        setObject(props.baseObject);
        setProps(props);
    }, [props, direction, showDetails, showBroadcasters, showThumbnails, showClusters]);

    const changeLayout = useCallback(
        (direction: string) => {
            (async () => {
                // Set direction
                setDirection(direction);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramOrientation", direction);
            })();
        },
        [props, showDetails, showBroadcasters, showThumbnails, showClusters]
    );

    const onInit = (reactFlowInstance: ReactFlowInstance) => {
        // lock
        const buttons = document.getElementsByClassName("react-flow__controls-button react-flow__controls-interactive");
        const buttonToClick = buttons[0] as HTMLElement;
        buttonToClick.click();
        // fitview
        reactFlowInstance.fitView();
    };

    // Calculate the initial layout on mount.
    useLayoutEffect(() => {
        changeLayout(direction);
    }, []);

    const toggleDetails = useCallback(
        (showDetails: boolean) => {
            (async () => {
                // Set direction
                setShowDetails(showDetails => !showDetails);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramDetails", showDetails ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showThumbnails, showClusters]
    );

    const toggleBroadcasters = useCallback(
        (showBroadcasters: boolean) => {
            (async () => {
                setShowBroadcasters(showBroadcasters => !showBroadcasters);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramBroadcasters", showBroadcasters ? "true" : "false");
            })();
        },
        [props, direction, showDetails, showThumbnails, showClusters]
    );

    const toggleThumbnails = useCallback(
        (showThumbnails: boolean) => {
            (async () => {
                // Set direction
                setShowThumbnails(showThumbnails => !showThumbnails);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramThumbnails", showThumbnails ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showDetails, showClusters]
    );

    const toggleClusters = useCallback(
        (showClusters: boolean) => {
            (async () => {
                // Set direction
                setShowClusters(showClusters => !showClusters);
                const { children: dataNodes, edges: dataEdges } = formatData(props, direction, showDetails, showBroadcasters, showThumbnails, showClusters);
                await layoutNodes(dataNodes, dataEdges, direction);
                localStorage.setItem("diagramClusters", showClusters ? "true" : "false");
            })();
        },
        [props, direction, showBroadcasters, showDetails, showThumbnails]
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {nodes && edges && (
                <ReactFlow
                    onInit={onInit}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    fitView
                    minZoom={0.1}
                    maxZoom={1.25}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    attributionPosition="bottom-right"
                    defaultEdgeOptions={{
                        zIndex: 1001
                    }}
                >
                    <Panel position="top-right">
                        <div className="diagramSettings">
                            <Button
                                id="settings-button"
                                aria-controls={open ? "setting-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                variant="outlined"
                                color="secondary"
                                size="small"
                            >
                                <i className="fa fa-cog fa-sm"></i>
                            </Button>
                            <Menu
                                id="settings-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": "settings-button"
                                }}
                            >
                                <MenuItem className="text-secondary" onClick={() => toggleBroadcasters(!showBroadcasters)}>
                                    {showBroadcasters && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showBroadcasters && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Broadcasters
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleClusters(!showClusters)}>
                                    {showClusters && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showClusters && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Clusters
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleDetails(!showDetails)}>
                                    {showDetails && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showDetails && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Details
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => toggleThumbnails(!showThumbnails)}>
                                    {showThumbnails && <i className="fa fa-square-check fa-md fa-fw me-1"></i>}
                                    {!showThumbnails && <i className="far fa-square fa-md fa-fw me-1"></i>}Show Thumbnails
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => changeLayout("RIGHT")}>
                                    <i className="fa fa-arrow-alt-from-left fa-sm fa-fw me-1"></i>Horizontal Layout
                                </MenuItem>
                                <MenuItem className="text-secondary" onClick={() => changeLayout("DOWN")}>
                                    <i className="fa fa-arrow-alt-from-top fa-sm fa-fw me-1"></i>Vertical Layout
                                </MenuItem>
                            </Menu>
                        </div>
                    </Panel>
                    <Background />
                    <Controls position="top-left" showInteractive={true} />
                    <MiniMap position="bottom-left" pannable zoomable nodeClassName={nodeClassNameMiniMap} />
                </ReactFlow>
            )}
        </div>
    );
};

// Set node class name on Mini Map
const nodeClassNameMiniMap = node => {
    return node.type + " " + node.data.status;
};

export default props => (
    <ReactFlowProvider>
        <ReactDiagramComponent {...props} />
    </ReactFlowProvider>
);
